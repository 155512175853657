export const config: any = {
	institutionName: "Whatcom Educational Credit Union",
	logoName: "https://s3.amazonaws.com/creditsnapimages/WECU/logo.png",
	logoUrl: "https://www.wecu.com/",
	phoneNumber: "360-676-1168",
	productSelection: false, // true for first page to show product selection with images
	multiSteps: false,
	stepsLocation: "", //top or "blank for no progress bar"
	imageFlow: false,
	imageForCollateral: false,
	branding: {},
	askOTP: true,
	otpVerifyChannel: 'SMS', // SMS or SMS_EMAIL
	showMiddleName: false,
	steps: {
		1: {
			title: "creditsnap Details",
			fields: {
				first_name: true,
				last_name: true,
				email: true,
				mobile_number: true,
				income: true,
				employment_length: true,
				rent_or_own: true,
				housing_cost: true,
				address_details: true,
				date_of_birth: true,
				citizenship: true,
				graduation_level: true,
				MLA: true,
				misc: true
			}
		},
		2: {
			title: "Test Details",
			fields: {
				income: true,
				employment_length: true,
				rent_or_own: true,
				housing_cost: true
			}
		},
		3: {
			title: "Personal details",
			fields: {
				income: true,
				employment_length: true,
				rent_or_own: true,
				housing_cost: true
			}
		}
	},
	defaultLoanAmount: 500,
	isCoBorrowerRequired: true,
	isCitizenshipRequired: false,
	employmentTypeList: [
		{name: "Full-Time", value: "F"},
		{name: "Part time", value: "P"},
		{name: "Temporary", value: "T"},
		{name: "Unemployed", value: "N"},
		{name: "Self Employed", value: "S"},
		{name: "Retired", value: "R"}/*,
		{ name: "Student", value: "ST" }*/

	],
	isPermanentResidentRequired: true,
	defaultRateType: "fixed",
	preEligibilityCheck: false,
	relationshipToApplicant: true,
	vehicleUndecided: true,
	hardInquiryConsent: "on-page", //modal
	residenceStatusOnAbout: true,
	showFullConsent: true,
	consentsConfig: {
		web: {
			consent1_individual: {
				text:`By providing information about yourself in this pre-qualification and selecting the box to the left, you consent, acknowledge, and agree to the following:
				<ul>
				<li>You are providing written instructions to WECU under the Fair Credit Reporting Act authorizing WECU to obtain information from your personal credit profile or other information from a credit bureau. We cannot pre-qualify you if your credit reporting file is frozen or otherwise inaccessible.</li>
				<li>You authorize WECU to obtain such information solely to provide you with credit pre-qualification offers and additional credit product offers.</li>
				<li>Information contained in your credit report will be used to qualify you for an offer. An offer is not guaranteed. Loan approval is subject to verification of income, debt-to-income ratio, credit information, and application information needed to meet the minimum requirements established for the offer.</li>
				<li> Consent to <a href="https://www.wecu.com/wp-content/uploads/Privacy-Policy_04282022.pdf" target="_blank"> Privacy Policy</a></li>
				<li> Consent to <a href="https://www.wecu.com/security/" target="_blank"> Security Policy</a></li>
				<li> Consent to <a href="https://www.wecu.com/wp-content/uploads/2019/07/Electronic-Services-Agreement.pdf" target="_blank"> Electronic Disclosure</a></li>
				</ul>`,
				required: true,
				consents: ['eConsent','SoftInQuiry'],
			},
			consent2_individual: {
				text: "I consent to be contacted by WECU about my application status through the information I have provided which may include email, SMS, or other communications.",
				required: false,
				consents: ['Marketing'],
			},
			//Skip offer/Cash apps
			consent3_individual: {
				text:`By providing information about yourself in this application and selecting the box to the left, you consent, acknowledge, and agree to the following:
				<ul>
				<li> Consent to <a href="https://www.wecu.com/wp-content/uploads/Privacy-Policy_04282022.pdf" target="_blank"> Privacy Policy</a></li>
				</ul>`,
				required: true,
				consents: ['eConsent'],
			},
			//preapproval
			consent4_individual: {
				text:'preapproval consent',
				required: true,
				consents: ['eConsent'],
			},
			consent1_joint: {
				text:`By providing information in this pre-qualification and selecting the box to the left, you agree that this request on behalf ' +
				'of yourself and one or more co-borrowers indicates your intent to apply for joint credit. By selecting the box to the left,
				you also consent, acknowledge, and agree to the following:
				, you consent, acknowledge, and agree to the following:
				<ul>
				<li>You are providing written instructions to WECU under the Fair Credit Reporting Act authorizing WECU to obtain information from your personal credit profile or other information from a credit bureau. We cannot pre-qualify you if your credit reporting file is frozen or otherwise inaccessible.</li>
				<li>You authorize WECU to obtain such information solely to provide you with credit pre-qualification offers and additional credit product offers.</li>

				<li> Consent to <a href="https://www.wecu.com/wp-content/uploads/Privacy-Policy_04282022.pdf" target="_blank"> Privacy Policy</a></li>
				</ul>`,
				required: true,
				consents: ['eConsent','SoftInQuiry'],
			},
			consent2_joint: {
				text: "I consent to be contacted by WECU about my application status through the information I have provided which may include email, SMS, or other communications.",
				required: false,
				consents: ['Marketing'],
			},
			//skipoffer joint
			consent3_joint: {
				text:"By selecting the box to the left, you agree that completing and submitting information on behalf " +
					"of yourself and one or more co-borrowers indicates your intent to apply for joint credit.",
				required: true,
				consents: ['eConsent'],
			},

		},
		teller: {
			consent1_individual: {
				text:`By providing information about yourself in this pre-qualification and selecting the box to the left, you consent, acknowledge, and agree to the following:
				<ul>
				<li>You are providing written instructions to WECU under the Fair Credit Reporting Act authorizing WECU to obtain information from your personal credit profile or other information from a credit bureau. We cannot pre-qualify you if your credit reporting file is frozen or otherwise inaccessible.</li>
				<li>You authorize WECU to obtain such information solely to provide you with credit pre-qualification offers and additional credit product offers.</li>
				<li>Information contained in your credit report will be used to qualify you for an offer. An offer is not guaranteed. Loan approval is subject to verification of income, debt-to-income ratio, credit information, and application information needed to meet the minimum requirements established for the offer.</li>
				<li> Consent to <a href="https://www.wecu.com/wp-content/uploads/Privacy-Policy_04282022.pdf" target="_blank"> Privacy Policy</a></li>
				</ul>`,
				required: true,
				consents: ['eConsent','SoftInQuiry'],
			},
			consent2_individual: {
				text: "I consent to be contacted by WECU about my application status through the information I have provided which may include email, SMS, or other communications.",
				required: false,
				consents: ['Marketing'],
			},
			//Skip offer/Cash apps
			consent3_individual: {
				text:`By providing information about yourself in this application and selecting the box to the left, you consent, acknowledge, and agree to the following:
				<ul>
				<li> Consent to <a href="https://www.wecu.com/wp-content/uploads/Privacy-Policy_04282022.pdf" target="_blank"> Privacy Policy</a></li>
				</ul>`,
				required: true,
				consents: ['eConsent'],
			},
			//preapproval
			consent4_individual: {
				text:'preapproval consent',
				required: true,
				consents: ['eConsent'],
			},
			consent1_joint: {
				text:`By providing information in this pre-qualification and selecting the box to the left, you agree that this request on behalf ' +
				'of yourself and one or more co-borrowers indicates your intent to apply for joint credit. By selecting the box to the left,
				you also consent, acknowledge, and agree to the following:
				, you consent, acknowledge, and agree to the following:
				<ul>
				<li>You are providing written instructions to WECU under the Fair Credit Reporting Act authorizing WECU to obtain information from your personal credit profile or other information from a credit bureau. We cannot pre-qualify you if your credit reporting file is frozen or otherwise inaccessible.</li>
				<li>You authorize WECU to obtain such information solely to provide you with credit pre-qualification offers and additional credit product offers.</li>

				<li> Consent to <a href="https://www.wecu.com/wp-content/uploads/Privacy-Policy_04282022.pdf" target="_blank"> Privacy Policy</a></li>
				</ul>`,
				required: true,
				consents: ['eConsent','SoftInQuiry'],
			},
			consent2_joint: {
				text: "I consent to be contacted by WECU about my application status through the information I have provided which may include email, SMS, or other communications.",
				required: false,
				consents: ['Marketing'],
			},
			//skipoffer joint
			consent3_joint: {
				text:"By selecting the box to the left, you agree that completing and submitting information on behalf " +
					"of yourself and one or more co-borrowers indicates your intent to apply for joint credit.",
				required: true,
				consents: ['eConsent'],
			},
		},
	},

	hardInquiryConsentText: "You authorize WECU to obtain your credit report from a credit reporting company. " +
		"This is more extensive than pre-qualification and will result in a credit inquiry which may impact your credit score. You acknowledge that this " +
		"is an application for credit and authorize WECU to obtain such information solely to evaluate your credit application. You are " +
		"also consenting to allow WECU to contact you and offer you savings and benefits on other products.",
	hardInquiryConsentText_joint: "You authorize WECU to obtain your credit report from a credit reporting company. " +
		"This is more extensive than pre-qualification and will result in a credit inquiry which may impact your credit score. You acknowledge that this " +
		"is an application for credit and authorize WECU to obtain such information solely to evaluate your credit application. You are " +
		"also consenting to allow WECU to contact you and offer you savings and benefits on other products.",
	underwritingStatusMessages: {
		approved: {
			title: "Congratulations! Your application has been approved.",
			message: "A member of our team will reach out to you within one business day to review with next steps."
		},
		approved_cc: {
			title: "Congratulations! Your credit card application has been approved.",
			message: "A member of our team will reach out to you on the next business day with next steps."
		,
		approved_cli: {
			title: "Congratulations! Your credit limit increase has been approved.",
			message: "A member of our team will reach out to you on the next business day with next steps."
		}},
		pending: {
			title: "Thanks for applying! Your application is being processed.",
			message: "A member of our team will reach out to you within one business day."
		},
		decline: {
			title: "Thanks for applying! Your application is being processed.",
			message: "A member of our team will reach out to you within one business day."
		},
		default: {
			title: " Thanks for applying! Your application is being processed.",
			message: "A member of our team will reach out to you within one business day."
		}
	},
	crossSellOffersPage: {
		title: "We found additional personalized pre-qualified offers for you.",
		message: ""
	},
	footerConfig: {
		footerTop: {
			BGColor: "#5EB3E4",
			FontColor: "#000"
		},
		footerBottom: {
			BGColor: "#5EB3E4",
			FontColor: "#FFF",
			copyRight: "WECU",
			phone: "360-676-1168"
		}
	},
	ssnPrompt: true,
	mailingAddress: false,
	minHousingCost: 0,
	iovationIntegration: false,
	defaultIncome: 300,
	minMax: {
		loanAmount: {
			min: 500,
			max: 9999999
		}
	},
	// fraudCheckOnFinalSubmit: true,
	poboxAllowed: true,
	existingMemberPrompt: true,
	existingMemberFields: {
		lastName: true, // true
		dateOfBirth: true, // true
		zipCode: false,
		last4SSN: false, // true
		visible: false,
		firstName: false,
		email: false,
		memberNumber: true,
		existingMemberText: ""
	},
	membershipEligibilityCriteria: {
		visible: true,
		membershipString: "<p style=\"font-size: 125%;\"><strong> Whatcom Educational Credit Union membership is free and more accessible to you than you might think. You are eligible to join if:</strong></p>\n" +
			"\n" +
			"      <ul aria-labelledby=\"memberul\" class=\"orangesquare\">\n" +
			"        <li class=\"marginBottom10\">You are an employee of WECU</li>\n" +
			"        <li class=\"marginBottom10\">You are a family member of any existing WECU member.</li>\n" +
			"        <li class=\"marginBottom10\">You live, work, worship or attend school in WA.</li>\n" +
			"        <li class=\"marginBottom10\">You are a WWU Alumni.</li>\n" +
			"      </ul>",
		familyMember: true,
		sponsorOrganization: false,
		sponsorOrganizationType: "search",
		live: true,
		work: true,
		worship: true,
		study: true
	},
	membershipEligibilityOptions: [
		{value: "FAMILY_MEMBER", label: "Someone in my family is a WECU member"},
		{value: "ASSOCIATION_MEMBERSHIP_EXISTING", label: "I'm a WWU Alumni"},
		{value: "ASSOCIATION_MEMBERSHIP_EXISTING2", label: "I'm a WECU employee"},
		{value: "LIVING_AREA", label: "I live in WA"},
		{value: "WORK_AREA", label: "I work in WA"},
		{value: "WORSHIP_AREA", label: "I worship WA"},
		{value: "LEARN_AREA", label: "I attend school in WA"}
	],
	offerTerms: [6, 12, 24, 36, 48, 60, 72, 84],
	residenceStatusList: [
		{value: "OWN_MORTGAGE", label: "Mortgage"},
		{value: "OWN_FREE_CLEAR", label: "Own Free and Clear"},
		{value: "RENT", label: "Rent"},
		{value: "LIVING_WITH_PARENTS", label: "Living with Parents"},
		{value: "LIVING_WITH_SOMEONE", label: "Living with someone"},
		{value: "OTHER", label: "Other"},
		{value: "NOT_SELECTED", label: "Not Selected"}
	],
	additionalIncome: {
		startDate: true,
		types: [
			{
				id: "AL",
				text: "Alimony"
			},
			{
				id: "CS",
				text: "Child Support"
			},
			{
				id: "I",
				text: "Investment Income"
			},
			{
				id: "S",
				text: "Social Security"
			},
			{
				id: "R",
				text: "Retirement Income"
			},
			{
				id: "RP",
				text: "Rental Property Income"
			},
			{
				id: "O",
				text: "Other"
			}
		]
	},
	loanPurpose: {
		AN: {
			id: "AN",
			title: "New Vehicle Purchase",
			productName: "AUTO",
			isMLARequired: false,
			vehicleUndecided: true,
			isRefinance: false,
			skipOfferScreen: false,
			orderCreditBureau: true,
			finalSubmitNoHit: true,
			termBasedLoan: true,
			offerTerms: [36, 48, 60, 72, 84],
			fraudCheckAPICall: "",
			aboutYouPage: {
				employmentType: true,
				employmentLength: true,
				residenceLength: false,
				ssn: false,
				additionalIncome: false,

			},
			addOnProducts: {
				visible: false,
				debtProtection: false
			},
			tradeLine: {
				isLienHolderNameAvailable: false,
				required: false,
				multiselect: false,
				code: "AUTO",
				tradeLineDisplay: "Auto"
			},
			collateral: {
				required: true,
				type: "AUTO",
				yearMakeModelSelection: true,
				years: "new" // all, new, old
			},
			offer: {
				slider: true,
				downPayment: false,
				saving: false
			},
			phone: "360-676-1168",
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 1000,
			maxLoanAmount: 75000,
			minCoBorrowerAnnualIncome: 100,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: "purchasePrice",
			defaultLoanAmount: 75000,
			othersOnTitle: false,
			hardInquiryConsent: "on-page",
			conditionalProvisioning: true,
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: false,
				showVin: false,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "nodisplay", // nodisplay/optional/required
					mothersMaidenName: false,
					ssn: true,
					last4ssn: false,
					workPhone: false,
					desiredLoanAmount: true,
					desiredTerm: true,
					desiredTermSelection: true
				},
				employmentHistoryDuration: 12,
				residenceHistoryDuration: 0,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				}
			},
			documents: [
				// 'Paystub', 'W2', 'Driving License', 'Passport', 'Loan Payoff Document', 'Title', 'Other'
				{type: "Paystub", required: true},
				{type: "W2", required: true},
				{type: "Driving License", required: true},
				{type: "Passport", required: true},
				{type: "Loan Payoff Document", required: false},
				{type: "Title", required: false},
				{type: "Other", required: false}
			],
			crossSellPageSubTitle: "Save {{monthlySavings}} on your current loan payment.",
			defaultOfferVariationObject: {}
		},
		AU: {
			id: "AU",
			title: "Used Vehicle Purchase",
			productName: "AUTO",
			isMLARequired: false,
			vehicleUndecided: true,
			isRefinance: false,
			skipOfferScreen: false,
			orderCreditBureau: true,
			finalSubmitNoHit: true,
			termBasedLoan: true,
			offerTerms: [36, 48, 60, 72, 84],
			fraudCheckAPICall: "",
			aboutYouPage: {
				employmentType: true,
				employmentLength: true,
				additionalIncome: false
			},
			addOnProducts: {
				visible: false,
				debtProtection: false
			},
			tradeLine: {
				isLienHolderNameAvailable: false,
				required: false,
				multiselect: false,
				code: "AUTO",
				tradeLineDisplay: "Auto"
			},
			collateral: {
				required: true,
				type: "AUTO",
				yearMakeModelSelection: true,
				years: "all" // all, new, old
			},
			offer: {
				slider: true,
				downPayment: false,
				saving: false
			},
			phone: "360-676-1168",
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 1000,
			maxLoanAmount: 75000,
			minCoBorrowerAnnualIncome: 100,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: "purchasePrice",
			defaultLoanAmount: 75000,
			othersOnTitle: false,
			hardInquiryConsent: "on-page",
			finalSubmitPagesCount: 1,
			conditionalProvisioning: true,
			finalSubmitPages: {
				bankDetails: false,
				showVin: false,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "nodisplay", // nodisplay/optional/required
					mothersMaidenName: false,
					ssn: true,
					last4ssn: false,
					workPhone: false,
					desiredLoanAmount: true,
					desiredTerm: true,
					desiredTermSelection: true
				},
				employmentHistoryDuration: 12,
				residenceHistoryDuration: 0,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				}
			},
			documents: [
				// 'Paystub', 'W2', 'Driving License', 'Passport', 'Loan Payoff Document', 'Title', 'Other'
				{type: "Paystub", required: true},
				{type: "W2", required: true},
				{type: "Driving License", required: true},
				{type: "Passport", required: true},
				{type: "Loan Payoff Document", required: false},
				{type: "Title", required: false},
				{type: "Other", required: false}
			],
			crossSellPageSubTitle: "",
			defaultOfferVariationObject: {},
		},
		R: {
			id: "R",
			title: "Vehicle Loan Refinance",
			productName: "AUTO",
			isMLARequired: false,
			isRefinance: true,
			skipOfferScreen: false,
			orderCreditBureau: true,
			finalSubmitNoHit: true,
			termBasedLoan: true,
			offerTerms: [36, 48, 60, 72, 84],
			fraudCheckAPICall: "",
			aboutYouPage: {
				employmentType: true,
				employmentLength: true,
				additionalIncome: false
			},
			addOnProducts: {
				visible: false,
				debtProtection: false
			},
			tradeLine: {
				isLienHolderNameAvailable: false,
				required: true,
				multiselect: false,
				code: "AUTO",
				tradeLineDisplay: "Auto"
			},
			collateral: {
				required: true,
				type: "AUTO",
				yearMakeModelSelection: true,
				years: "all" // all, new, old
			},
			offer: {
				slider: false,
				downPayment: false,
				saving: true
			},
			phone: "360-676-1168",
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 1000,
			maxLoanAmount: 75000,
			minCoBorrowerAnnualIncome: 100,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: "retailValue",
			defaultLoanAmount: 75000,
			othersOnTitle: false,
			hardInquiryConsent: "on-page",
			conditionalProvisioning: true,
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: false,
				showVin: false,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "nodisplay", // nodisplay/optional/required
					mothersMaidenName: false,
					ssn: true,
					last4ssn: false,
					workPhone: false,
					desiredLoanAmount: true,
					desiredTerm: true,
					desiredTermSelection: true
				},
				employmentHistoryDuration: 12,
				residenceHistoryDuration: 0,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				}
			},
			documents: [
				// 'Paystub', 'W2', 'Driving License', 'Passport', 'Loan Payoff Document', 'Title', 'Other'
				{type: "Paystub", required: true},
				{type: "W2", required: true},
				{type: "Driving License", required: true},
				{type: "Passport", required: true},
				{type: "Loan Payoff Document", required: false},
				{type: "Title", required: false},
				{type: "Other", required: false}
			],
			crossSellPageSubTitle: "Save {{monthlySavings}} on your current loan payment.",
			defaultOfferVariationObject: {}
		},
		CC: {
			id: "CC",
			title: "Credit Card",
			productName: "CREDIT_CARD",
			isMLARequired: false,
			isRefinance: false,
			skipOfferScreen: false,
			orderCreditBureau: true,
			finalSubmitNoHit: true,
			termBasedLoan: false,
			fraudCheckAPICall: "",
			aboutYouPage: {
				employmentType: true,
				employmentLength: true,
				additionalIncome: false
			},
			preScreenPage: {
				grossAnnualIncome: true,
				rentMortgagePmt: true
			},
			addOnProducts: {
				visible: true,
				debtProtection: true
			},
			tradeLine: {
				isLienHolderNameAvailable: false,
				required: false,
				multiselect: true,
				code: "CREDIT_CARD",
				tradeLineText: "If approved, would you like to transfer an existing balance to your WECU Visa?",
				tradeLineDisplay: "Credit Card"
			},
			collateral: {
				required: false,
				type: "CREDIT_CARD"
			},
			offer: {
				slider: true,
				downPayment: false,
				saving: false
			},
			phone: "360-676-1168",
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 500,
			maxLoanAmount: 30000,
			minCoBorrowerAnnualIncome: 100,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: "",
			defaultLoanAmount: 30000,
			othersOnTitle: false,
			defaultTermsAndConditionsURL: "https://www.wecu.com/personal-banking/credit-cards/",
			offerVariationDetails: {
				VISA_LUX: {
					imageUrl: "https://creditsnapimages.s3.amazonaws.com/WECU/VISA_LUX.png",
					termsAndConditionsURL: "https://www.wecu.com/wp-content/uploads/Lux-Visa-Application-Agreement.pdf",
					title: "Lux Visa",
					subTitle: "",
					shortInfo: [
						"Up to {{maxAvailableCreditFormatted}} credit limit",
						"0% intro APR for 12 months",
						"Variable rate after intro – currently at {{purchaseAPR}}%",
						"Up to 3x rewards for purchases",
						"25,000 bonus points with $2,500 spent in first 3 months",
						"Tap to pay!"
					],
					longInfo: "<ul>" +
					"<li>Up to {{maxAvailableCreditFormatted}} credit limit</li> " +
					"<li>0% intro APR for 12 months</li> " +
					"<li>Variable rate after intro – currently at {{purchaseAPR}}%</li>"	+
					"<li>Up to 3x rewards for purchases</li> " +
					"<li>25,000 points with $2,500 spent in first 3 months</li> " +
					"<li>Tap to pay!</li> " +
					"<li>Visa Signature Benefits</li> " +
					"<li>No-Fee Balance Transfers</li> " +
					"<li>Optional Payment Protection Available</li> " +
					"</ul>"

				},
				VISA_CHOICE: {
					imageUrl: "https://creditsnapimages.s3.amazonaws.com/WECU/visa_choice_mar2023.png",
					termsAndConditionsURL: "https://www.wecu.com/wp-content/uploads/Choice-Visa-Application-Agreement-Final-3.21.2023.pdf",
					title: "Choice Visa",
					subTitle: "",
					shortInfo: [
						"Up to {{maxAvailableCreditFormatted}} credit limit",
						"Variable rate - currently at {{purchaseAPR}}%.",
						"Earn 1.5 reward points for every dollar you spend (subject to Member Rewards Terms & Conditions)",
						"Tap to pay!"
					],
					longInfo: "<ul>" +
						"<li>Earn 1.5 Rewards Points for every dollar you spend. (Subject to transaction restrictions found in the WECU Visa Rewards Terms & Conditions.)</li>" +
						"<li>Redeem True Blue Rewards Points at $.01 per point for cash, travel, gift cards or for a charity of your choice.</li>" +
						"<li>No caps on how many points you can accumulate.</li>" +
						"<li>True Blue Rewards Points don’t expire.</li>" +
						"<li>No international transaction fee.</li>" +
						"<li>No-Fee Balance Transfers.</li>" +
						"</ul>"

				},
				VISA_CLASSIC: {
					imageUrl: "https://creditsnapimages.s3.amazonaws.com/WECU/visa_classic_mar2023.png",
					termsAndConditionsURL: "https://www.wecu.com/wp-content/uploads/Classic-Visa-Application-Agreement-Final-3.21.2023.pdf",
					title: "Classic Visa",
					subTitle: "",
					shortInfo: [
						"Up to {{maxAvailableCreditFormatted}} credit limit.",
						"Fixed rate {{purchaseAPR}}% APR.",
						"1% cash back reward on purchases.",
						"No-Fee Balance Transfers.",
						"Tap to pay!"
					],
					longInfo: "<ul>" +
						"<li>1% cash back reward on purchases.</li>" +
						"<li>No-Fee Balance Transfers. </li>" +
						"<li>Optional Payment Protection available. </li>" +
						"<li>Benefits provided by Visa.</li>" +
						"</ul>"
				},
				// VISA_PLATINUM: {
				// 	imageUrl: "https://creditsnapimages.s3.amazonaws.com/WECU/VISA_PLATINUM.png",
				// 	termsAndConditionsURL: "https://www.wecu.com/wp-content/uploads/Classic-Platinum-Student-and-Secured-Fixed-Credit-Card-Application.pdf",
				// 	title: "Platinum Visa",
				// 	subTitle: "",
				// 	shortInfo: [
				// 		"Up to {{maxAvailableCreditFormatted}} credit limit",
				// 		"Fixed rate {{purchaseAPR}}% APR, {{cashAdvanceAPR}}% for cash advances ",
				// 		"1% cash back reward on purchases.",
				// 		"Tap to pay!"
				// 	],
				// 	longInfo: "<ul>" +
				// 		"<li>1% cash back reward on purchases.</li>" +
				// 		"<li>No-Fee Balance Transfers </li>" +
				// 		"<li>Optional Payment Protection available.</li>" +
				// 		"<li>Benefits provided by Visa.</li>" +
				// 		"</ul>"

				// },

			},
			hardInquiryConsent: "on-page",
			finalSubmitPagesCount: 1,
			conditionalProvisioning: true,
			finalSubmitPages: {
				bankDetails: true,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "nodisplay", // nodisplay/optional/required
					mothersMaidenName: false,
					ssn: true,
					last4ssn: false,
					workPhone: false,
					desiredLoanAmount: true,
					desiredTerm: true,
					desiredTermSelection: true
				},
				employmentHistoryDuration: 12,
				residenceHistoryDuration: 0,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				}
			},
			cardImageSelector: {
				visible: false,
				cardImages: []
			},
			documents: [
				// 'Paystub', 'W2', 'Driving License', 'Passport', 'Loan Payoff Document', 'Title', 'Other'
				{type: "Paystub", required: true},
				{type: "W2", required: true},
				{type: "Driving License", required: true},
				{type: "Passport", required: true},
				{type: "Loan Payoff Document", required: false},
				{type: "Title", required: false},
				{type: "Other", required: false}
			],
			crossSellPageSubTitle: "",
			defaultOfferVariationObject: {
				purchaseIntroAPR: "",
				purchaseIntroPeriod: "",
				purchaseAPR: "Advantage Mastercard 5.99% - 18.00%. " +
					"Cash Back Mastercard 15.99% - 18.00%. ",
				balanceTransferIntroAPR: "0%",
				balanceTransferIntroPeriod: "12",
				balanceTransferAPR: "Advantage Mastercard 5.99% - 18.00%. " +
					"Cash Back Mastercard 15.99% - 18.00%. ",
				cashAdvanceAPR: "Advantage Mastercard 5.99% - 18.00%. " +
					"Cash Back Mastercard 15.99% - 18.00%. ",
				penaltyAPRDesc: "None",
				foreignTransactionDesc: "1.00% of each transaction in U.S. dollars completed outside the U.S. " +
					"0.80% of each transaction in U.S. dollars completed in a foreign currency ",
				returnedPaymentFeeDesc: "None",
				latePaymentFeeDesc: "$29"
			}
		},
		PP: {
			id: "PP",
			title: "Personal Signature Loan",
			productName: "OTHER",
			hideFromDropdown: false,
			isMLARequired: false,
			isRefinance: false,
			iovation: false,
			finalSubmitNoHit: true,
			termBasedLoan: true,
			offerTerms: [24, 36, 48, 60],
			fraudCheckAPICall: "",
			skipOfferScreen: false,
			orderCreditBureau: true,
			aboutYouPage: {
				employmentType: true,
				employmentLength: true,
				additionalIncome: false
			},
			addOnProducts: {
				visible: false,
				debtProtection: false,
				gapProtection: false,
				creditProtection: true,
				mechanicalRepair: false
			},
			tradeLine: {
				isLienHolderNameAvailable: false,
				required: false,
				multiselect: false,
				code: "OTHER",
				tradeLineDisplay: "Auto"
			},
			collateral: {
				required: false,
				type: "OTHER"
			},
			offer: {
				slider: true,
				downPayment: false,
				saving: false
			},
			purposeCodeModal: true,
			purposeCodeList: [
				{value: "PP^Attorney Fees", label: "Attorney Fees"},
				{value: "PP^Collections", label: "Collections"},
				{value: "PPDC^Debt Consolidation", label: "Debt Consolidation"},
				{value: "PP^Funeral", label: "Funeral"},
				{value: "PP^Home Upgrades", label: "Home Upgrades"},
				{value: "PP^Medical Bills", label: "Medical Bills"},
				{value: "PP^Vehicle Repairs", label: "Vehicle Repairs"},
				{value: "PP^Vacation", label: "Vacation"},
				{value: "PP^Wedding", label: "Wedding"},
				{value: "PP^Other", label: "Other"},
			],
			phone: "360-676-1168",
			minAge: 18,
			minAnnualIncome: "100",
			maxAnnualIncome: "500000",
			minLoanAmount: 500,
			maxLoanAmount: 30000,
			minCoBorrowerAnnualIncome: "",
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: "",
			defaultLoanAmount: 30000,
			offerVariationDetails: {},
			othersOnTitle: true,
			hardInquiryConsent: "on-page",
			finalSubmitPagesCount: 1,
			conditionalProvisioning: true,
			finalSubmitPages: {
				bankDetails: false,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "nodisplay",
					mothersMaidenName: false,
					ssn: true,
					last4ssn: false,
					workPhone: false,
					desiredLoanAmount: true,
					desiredTerm: true,
					desiredTermSelection: true,
					balanceTransferAmount: "nodisplay" // nodisplay/optional/required
				},
				employmentHistoryDuration: 12,
				residenceHistoryDuration: 0,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				}
			},
			crossSellPageSubTitle: ""
		},
		PPDC: {
			id: "PPDC",
			title: "Personal Loan - Debt Consolidation",
			productName: "OTHER",
			hideFromDropdown: false,
			isMLARequired: false,
			isRefinance: false,
			iovation: false,
			finalSubmitNoHit: true,
			termBasedLoan: true,
			offerTerms: [24, 36, 48, 60],
			fraudCheckAPICall: "",
			skipOfferScreen: false,
			orderCreditBureau: true,
			personalLoanPurposeCode: true,
			purposeCodeList: [
				{value: "PP^Medical Expenses", label: "Medical Expenses"},
				{value: "PP^Debt Consolidation", label: "Debt Consolidation"},
				{value: "PP^Education Expenses", label: "Education Expenses"},
				{value: "PP^Purchase", label: "Purchase"},
				{value: "PP^Repair", label: "Repair"},
				{value: "PP^Other", label: "Other"},
			],
			aboutYouPage: {
				employmentType: true,
				employmentLength: true,
				additionalIncome: false
			},
			addOnProducts: {
				visible: false,
				debtProtection: false,
				gapProtection: false,
				creditProtection: true,
				mechanicalRepair: false
			},
			tradeLine: {
				isLienHolderNameAvailable: true,
				required: false,
				multiselect: true,
				type: "CREDIT_CARD_PERSONAL",
				code: "CREDIT_CARD_PERSONAL",
				tradeLineDisplay: "Credit Card or Personal"
			},
			collateral: {
				required: false,
				type: "OTHER"
			},
			offer: {
				slider: true,
				downPayment: false,
				saving: false
			},
			phone: "360-676-1168",
			minAge: 18,
			minAnnualIncome: "100",
			maxAnnualIncome: "500000",
			minLoanAmount: 500,
			maxLoanAmount: 30000,
			minCoBorrowerAnnualIncome: "",
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: "",
			defaultLoanAmount: 30000,
			offerVariationDetails: {},
			othersOnTitle: true,
			hardInquiryConsent: "on-page",
			finalSubmitPagesCount: 1,
			conditionalProvisioning: true,
			finalSubmitPages: {
				bankDetails: false,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "nodisplay",
					mothersMaidenName: false,
					ssn: true,
					last4ssn: false,
					workPhone: false,
					desiredLoanAmount: true,
					desiredTerm: true,
					desiredTermSelection: true,
					balanceTransferAmount: "nodisplay" // nodisplay/optional/required
				},
				employmentHistoryDuration: 12,
				residenceHistoryDuration: 0,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				}
			},
			crossSellPageSubTitle: ""
		},
		CLI: {
			id: "CLI",
			title: "Credit Card Limit Increase",
			productName: "CREDIT_CARD",
			isMLARequired: false,
			isRefinance: false,
			skipOfferScreen: false,
			orderCreditBureau: true, // Skip Bureau call and offer screen
			finalSubmitNoHit: true,
			hideFromDropdown: false,
			termBasedLoan: false,
			fraudCheckAPICall: "",
			aboutYouPage: {
				employmentType: true,
				employmentLength: true,
				additionalIncome: false
			},
			preScreenPage: {
				grossAnnualIncome: true,
				rentMortgagePmt: true
			},
			addOnProducts: {
				visible: false,
				debtProtection: true
			},
			tradeLine: {
				isLienHolderNameAvailable: false,
				required: true,
				multiselect: false,
				code: "CREDIT_CARD",
				tradeLineText: "Select the Credit Card that you are requesting a Credit Limit increase on",
				tradeLineDisplay: "Credit Card"
			},
			collateral: {
				required: false,
				type: "CREDIT_CARD"
			},
			offer: {
				slider: true,
				downPayment: false,
				saving: false
			},
			phone: "360-676-1168",
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 500,
			maxLoanAmount: 30000,
			minCoBorrowerAnnualIncome: 100,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: "",
			defaultLoanAmount: 500,
			othersOnTitle: false,
			termsAndConditionsURL: "https://www.wecu.com/personal-banking/credit-cards/",
			offerVariationDetails: {
				VISA_CLASSIC: {
					imageUrl: "https://creditsnapimages.s3.amazonaws.com/WECU/VISA_CLASSIC.png",
					termsAndConditionsURL: "https://www.wecu.com/wp-content/uploads/Classic-Visa-Application-Agreement-Final-3.21.2023.pdf ",
					title: "WECU Visa Classic",
					subTitle: "",
					shortInfo: [
						"New Credit Limit of {{maxAvailableCreditFormatted}}",
					],
					longInfo: "New Credit Limit of {{maxAvailableCreditFormatted}}"
				},
				VISA_CHOICE: {
					imageUrl: "https://creditsnapimages.s3.amazonaws.com/WECU/VISA_CHOICE.png",
					termsAndConditionsURL: "https://www.wecu.com/wp-content/uploads/Choice-Visa-Application-Agreement-Final-3.21.2023.pdf",
					title: "WECU Visa Choice ",
					subTitle: "",
					shortInfo: [
						"New Credit Limit of {{maxAvailableCreditFormatted}}"
					],
					longInfo: "New Credit Limit of {{maxAvailableCreditFormatted}}"

				},
				VISA_LUX: {
					imageUrl: "https://creditsnapimages.s3.amazonaws.com/WECU/VISA_LUX.png",
					termsAndConditionsURL: "https://www.wecu.com/wp-content/uploads/Lux-Visa-Application-Agreement.pdf",
					title: "WECU Visa Lux",
					subTitle: "",
					shortInfo: [
						"New Credit Limit of {{maxAvailableCreditFormatted}}"
					],
					longInfo: "New Credit Limit of {{maxAvailableCreditFormatted}}"

				}
			},
			hardInquiryConsent: "on-page",
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: true,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "nodisplay", // nodisplay/optional/required
					mothersMaidenName: false,
					ssn: true,
					last4ssn: false,
					workPhone: false,
					desiredLoanAmount: true,
					desiredTerm: true,
					desiredTermSelection: true
				},
				employmentHistoryDuration: 12,
				residenceHistoryDuration: 0,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				}
			},
			cardImageSelector: {
				visible: false,
				cardImages: []
			},
			documents: [
				// 'Paystub', 'W2', 'Driving License', 'Passport', 'Loan Payoff Document', 'Title', 'Other'

			],
			crossSellPageSubTitle: "",
			defaultOfferVariationObject: {
				purchaseIntroAPR: "",
				purchaseIntroPeriod: "",
				purchaseAPR: "",
				balanceTransferIntroAPR: "0%",
				balanceTransferIntroPeriod: "12",
				balanceTransferAPR: "",
				cashAdvanceAPR: "",
				penaltyAPRDesc: "None",
				foreignTransactionDesc: "",
				returnedPaymentFeeDesc: "None",
				latePaymentFeeDesc: ""
			}
		},
		/* B: {
			id: 'B',
			title: 'Boat Loan',
			productName: 'AUTO_SPECIALTY',
			isMLARequired: false,
			isRefinance: false,
			iovation: false,
			finalSubmitNoHit: true,
			hideFromDropdown: false,
			termBasedLoan: true,
			offerTerms: [60, 96, 120, 180],
			skipOfferScreen: false,
			orderCreditBureau: true,
			addOnProducts: {
				visible: false,
				debtProtection: false,
				gapProtection: false,
				creditProtection: false,
				mechanicalRepair: false,
			},
			tradeLine: {
				isLienHolderNameAvailable: true,
				required: false,
				multiselect: false,
				code: 'OTHER',
				tradeLineDisplay: 'Auto'
			},
			collateral: {
				required: false,
				type: 'AUTO_SPECIALTY'
			},
			offer: {
				slider: true,
				downPayment: false,
				saving: false,
			},
			purposeCodeModal: true,
			purposeCodeList: [
				{ value: "BU^Purchase Boat", label: "Purchase Boat" },
				{ value: "BU^Refinance Boat", label: "Refinance Boat" },
			],
			phone: '1-360-676-1168',
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 1000,
			maxLoanAmountFormatted: 75000,
			minCoBorrowerAnnualIncome: 3600,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: '',
			defaultLoanAmount: '',
			offerVariationDetails: {},
			othersOnTitle: true,
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: true,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: 'nodisplay', // nodisplay/optional/required
					mothersMaidenName: true,
					ssn: true,
					last4ssn: false,
					workPhone: true,
					desiredLoanAmount: true,
					desiredTerm: true
				},
				employmentHistoryDuration: 12,
				residenceHistoryDuration: 24,
				page1: {
					reference: true,
					driverLicense: true,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: true,
				},
				page2: {
					reference: false,
					driverLicense: false,
					employmentHistory: false,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false,
				}
			},
			desiredLoanAmount: true,
			crossSellPageSubTitle: ''
		},
		BU: {
            id: 'BU',
            title: 'Boat Loan',
            productName: 'AUTO_SPECIALTY',
            isMLARequired: false,
            isRefinance: false,
            iovation: false,
            finalSubmitNoHit: true,
			hideFromDropdown: true,
            termBasedLoan: true,
			offerTerms: [60, 96, 120, 180],
            skipOfferScreen: false,
			orderCreditBureau: true,
            addOnProducts: {
                visible: false,
                debtProtection: false,
                gapProtection: false,
                creditProtection: false,
                mechanicalRepair: false,
            },
            tradeLine: {
                isLienHolderNameAvailable: true,
                required: false,
                multiselect: false,
                code: 'OTHER',
                tradeLineDisplay: 'Auto'
            },
            collateral: {
                required: false,
                type: 'AUTO_SPECIALTY'
            },
            offer: {
                slider: true,
                downPayment: false,
                saving: false,
            },
            phone: '1-360-676-1168',
            minAge: 18,
            minAnnualIncome: 100,
            maxAnnualIncome: 500000,
            minLoanAmount: 1000,
            maxLoanAmountFormatted: 75000,
            minCoBorrowerAnnualIncome: 3600,
            hardInquiryConsentRequired: true,
            sliderDefaultAmountType: '',
            defaultLoanAmount: '',
            offerVariationDetails: {},
            othersOnTitle: true,
            finalSubmitPagesCount: 1,
            finalSubmitPages: {
                bankDetails: true,
                miscellaneousFields: {
                    email: false,
                    phone: false,
                    homePhone: 'nodisplay', // nodisplay/optional/required
                    mothersMaidenName: true,
                    ssn: true,
                    last4ssn: false,
                    workPhone: true,
                    desiredLoanAmount: true,
                    desiredTerm: true
                },
                employmentHistoryDuration: 12,
                residenceHistoryDuration: 24,
                page1: {
                    reference: true,
                    driverLicense: true,
                    employmentHistory: true,
                    residencyHistory: true,
                    declarations: false,
                    assetsLiabilities: false,
                    additionalIncome: true,
                },
                page2: {
                    reference: false,
                    driverLicense: false,
                    employmentHistory: false,
                    residencyHistory: true,
                    declarations: false,
                    assetsLiabilities: false,
                    additionalIncome: false,
                }
            },
            desiredLoanAmount: true,
            crossSellPageSubTitle: ''
        },
		M: {
			id: 'M',
			title: 'Motorcycle Loan',
			productName: 'AUTO_SPECIALTY',
			isMLARequired: false,
			isRefinance: false,
			iovation: false,
			finalSubmitNoHit: true,
			hideFromDropdown: false,
			termBasedLoan: true,
			offerTerms: [48, 60, 72, 84],
			skipOfferScreen: false,
			orderCreditBureau: true,
			addOnProducts: {
				visible: false,
				debtProtection: false,
				gapProtection: false,
				creditProtection: false,
				mechanicalRepair: false,
			},
			tradeLine: {
				isLienHolderNameAvailable: true,
				required: false,
				multiselect: false,
				code: 'OTHER',
				tradeLineDisplay: 'Auto'
			},
			collateral: {
				required: false,
				type: 'AUTO_SPECIALTY'
			},
			offer: {
				slider: true,
				downPayment: false,
				saving: false,
			},
			purposeCodeModal: true,
			purposeCodeList: [
				{ value: "MU^Purchase Motorcycle", label: "Purchase Motorcycle" },
				{ value: "MU^Refinance Motorcycle", label: "Refinance Motorcycle" },
			],
			phone: '1-360-676-1168',
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 1000,
			maxLoanAmountFormatted: 75000,
			minCoBorrowerAnnualIncome: 3600,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: '',
			defaultLoanAmount: '',
			offerVariationDetails: {},
			othersOnTitle: true,
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: true,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: 'nodisplay', // nodisplay/optional/required
					mothersMaidenName: true,
					ssn: true,
					last4ssn: false,
					workPhone: true,
					desiredLoanAmount: true,
					desiredTerm: true
				},
				employmentHistoryDuration: 12,
				residenceHistoryDuration: 24,
				page1: {
					reference: true,
					driverLicense: true,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: true,
				},
				page2: {
					reference: false,
					driverLicense: false,
					employmentHistory: false,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false,
				}
			},
			desiredLoanAmount: true,
			crossSellPageSubTitle: ''
		},
		MU: {
            id: 'MU',
            title: 'Motorcycle Loan',
            productName: 'AUTO_SPECIALTY',
            isMLARequired: false,
            isRefinance: false,
            iovation: false,
            finalSubmitNoHit: true,
			hideFromDropdown: true,
            termBasedLoan: true,
			offerTerms: [48, 60, 72, 84],
            skipOfferScreen: false,
			orderCreditBureau: true,
            addOnProducts: {
                visible: false,
                debtProtection: false,
                gapProtection: false,
                creditProtection: false,
                mechanicalRepair: false,
            },
            tradeLine: {
                isLienHolderNameAvailable: true,
                required: false,
                multiselect: false,
                code: 'OTHER',
                tradeLineDisplay: 'Auto'
            },
            collateral: {
                required: false,
                type: 'AUTO_SPECIALTY'
            },
            offer: {
                slider: true,
                downPayment: false,
                saving: false,
            },
            phone: '1-360-676-1168',
            minAge: 18,
            minAnnualIncome: 100,
            maxAnnualIncome: 500000,
            minLoanAmount: 1000,
            maxLoanAmountFormatted: 75000,
            minCoBorrowerAnnualIncome: 3600,
            hardInquiryConsentRequired: true,
            sliderDefaultAmountType: '',
            defaultLoanAmount: '',
            offerVariationDetails: {},
            othersOnTitle: true,
            finalSubmitPagesCount: 1,
            finalSubmitPages: {
                bankDetails: true,
                miscellaneousFields: {
                    email: false,
                    phone: false,
                    homePhone: 'nodisplay', // nodisplay/optional/required
                    mothersMaidenName: true,
                    ssn: true,
                    last4ssn: false,
                    workPhone: true,
                    desiredLoanAmount: true,
                    desiredTerm: true
                },
                employmentHistoryDuration: 12,
                residenceHistoryDuration: 24,
                page1: {
                    reference: true,
                    driverLicense: true,
                    employmentHistory: true,
                    residencyHistory: true,
                    declarations: false,
                    assetsLiabilities: false,
                    additionalIncome: true,
                },
                page2: {
                    reference: false,
                    driverLicense: false,
                    employmentHistory: false,
                    residencyHistory: true,
                    declarations: false,
                    assetsLiabilities: false,
                    additionalIncome: false,
                }
            },
            desiredLoanAmount: true,
            crossSellPageSubTitle: ''
        },
		RV: {
			id: 'RV',
			title: 'RV Loan',
			productName: 'AUTO_SPECIALTY',
			isMLARequired: false,
			isRefinance: false,
			iovation: false,
			finalSubmitNoHit: true,
			hideFromDropdown: false,
			termBasedLoan: true,
			offerTerms: [60, 96, 120, 144, 180],
			skipOfferScreen: false,
			orderCreditBureau: true,
			addOnProducts: {
				visible: false,
				debtProtection: false,
				gapProtection: false,
				creditProtection: false,
				mechanicalRepair: false,
			},
			tradeLine: {
				isLienHolderNameAvailable: true,
				required: false,
				multiselect: false,
				code: 'OTHER',
				tradeLineDisplay: 'Auto'
			},
			collateral: {
				required: false,
				type: 'AUTO_SPECIALTY'
			},
			offer: {
				slider: true,
				downPayment: false,
				saving: false,
			},
			purposeCodeModal: true,
			purposeCodeList: [
				{ value: "RVU^Purchase RV", label: "Purchase RV" },
				{ value: "RVU^Refinance RV", label: "Refinance RV" },
			],
			phone: '1-360-676-1168',
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 1000,
			maxLoanAmountFormatted: 75000,
			minCoBorrowerAnnualIncome: 3600,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: '',
			defaultLoanAmount: '',
			offerVariationDetails: {},
			othersOnTitle: true,
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: true,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: 'nodisplay', // nodisplay/optional/required
					mothersMaidenName: true,
					ssn: true,
					last4ssn: false,
					workPhone: true,
					desiredLoanAmount: true,
					desiredTerm: true
				},
				employmentHistoryDuration: 12,
				residenceHistoryDuration: 24,
				page1: {
					reference: true,
					driverLicense: true,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: true,
				},
				page2: {
					reference: false,
					driverLicense: false,
					employmentHistory: false,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false,
				}
			},
			desiredLoanAmount: true,
			crossSellPageSubTitle: ''
		},
		RVU: {
            id: 'RVU',
            title: 'RV Loan',
            productName: 'AUTO_SPECIALTY',
            isMLARequired: false,
            isRefinance: false,
            iovation: false,
            finalSubmitNoHit: true,
			hideFromDropdown: true,
            termBasedLoan: true,
			offerTerms: [60, 96, 120, 144, 180],
            skipOfferScreen: false,
			orderCreditBureau: true,
            addOnProducts: {
                visible: false,
                debtProtection: false,
                gapProtection: false,
                creditProtection: false,
                mechanicalRepair: false,
            },
            tradeLine: {
                isLienHolderNameAvailable: true,
                required: false,
                multiselect: false,
                code: 'OTHER',
                tradeLineDisplay: 'Auto'
            },
            collateral: {
                required: false,
                type: 'AUTO_SPECIALTY'
            },
            offer: {
                slider: true,
                downPayment: false,
                saving: false,
            },
            phone: '1-360-676-1168',
            minAge: 18,
            minAnnualIncome: 100,
            maxAnnualIncome: 500000,
            minLoanAmount: 1000,
            maxLoanAmountFormatted: 75000,
            minCoBorrowerAnnualIncome: 3600,
            hardInquiryConsentRequired: true,
            sliderDefaultAmountType: '',
            defaultLoanAmount: '',
            offerVariationDetails: {},
            othersOnTitle: true,
            finalSubmitPagesCount: 1,
            finalSubmitPages: {
                bankDetails: true,
                miscellaneousFields: {
                    email: false,
                    phone: false,
                    homePhone: 'nodisplay', // nodisplay/optional/required
                    mothersMaidenName: true,
                    ssn: true,
                    last4ssn: false,
                    workPhone: true,
                    desiredLoanAmount: true,
                    desiredTerm: true
                },
                employmentHistoryDuration: 12,
                residenceHistoryDuration: 24,
                page1: {
                    reference: true,
                    driverLicense: true,
                    employmentHistory: true,
                    residencyHistory: true,
                    declarations: false,
                    assetsLiabilities: false,
                    additionalIncome: true,
                },
                page2: {
                    reference: false,
                    driverLicense: false,
                    employmentHistory: false,
                    residencyHistory: true,
                    declarations: false,
                    assetsLiabilities: false,
                    additionalIncome: false,
                }
            },
            desiredLoanAmount: true,
            crossSellPageSubTitle: ''
        },
		MS: {
			id: 'MS',
			title: 'Misc Vehicle Loan',
			productName: 'AUTO_SPECIALTY',
			isMLARequired: false,
			isRefinance: false,
			iovation: false,
			finalSubmitNoHit: true,
			hideFromDropdown: false,
			termBasedLoan: true,
			offerTerms: [24, 48, 60],
			skipOfferScreen: false,
			orderCreditBureau: true,
			addOnProducts: {
				visible: false,
				debtProtection: false,
				gapProtection: false,
				creditProtection: false,
				mechanicalRepair: false,
			},
			tradeLine: {
				isLienHolderNameAvailable: true,
				required: false,
				multiselect: false,
				code: 'OTHER',
				tradeLineDisplay: 'Auto'
			},
			collateral: {
				required: false,
				type: 'AUTO_SPECIALTY'
			},
			offer: {
				slider: true,
				downPayment: false,
				saving: false,
			},
			purposeCodeModal: true,
			purposeCodeList: [
				{ value: "MSU^Purchase Misc Vehicle", label: "Purchase Misc Vehicle" },
				{ value: "MSU^Refinance Misc Vehicle", label: "Refinance Misc Vehicle" },
			],
			phone: '1-360-676-1168',
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 1000,
			maxLoanAmountFormatted: 75000,
			minCoBorrowerAnnualIncome: 3600,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: '',
			defaultLoanAmount: '',
			offerVariationDetails: {},
			othersOnTitle: true,
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: true,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: 'nodisplay', // nodisplay/optional/required
					mothersMaidenName: true,
					ssn: true,
					last4ssn: false,
					workPhone: true,
					desiredLoanAmount: true,
					desiredTerm: true
				},
				employmentHistoryDuration: 12,
				residenceHistoryDuration: 24,
				page1: {
					reference: true,
					driverLicense: true,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: true,
				},
				page2: {
					reference: false,
					driverLicense: false,
					employmentHistory: false,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false,
				}
			},
			desiredLoanAmount: true,
			crossSellPageSubTitle: ''
		},
		MSU: {
            id: 'MSU',
            title: 'Misc Vehicle Loan',
            productName: 'AUTO_SPECIALTY',
            isMLARequired: false,
            isRefinance: false,
            iovation: false,
            finalSubmitNoHit: true,
			hideFromDropdown: true,
            termBasedLoan: true,
			offerTerms: [24, 48, 60],
            skipOfferScreen: false,
			orderCreditBureau: true,
            addOnProducts: {
                visible: false,
                debtProtection: false,
                gapProtection: false,
                creditProtection: false,
                mechanicalRepair: false,
            },
            tradeLine: {
                isLienHolderNameAvailable: true,
                required: false,
                multiselect: false,
                code: 'OTHER',
                tradeLineDisplay: 'Auto'
            },
            collateral: {
                required: false,
                type: 'AUTO_SPECIALTY'
            },
            offer: {
                slider: true,
                downPayment: false,
                saving: false,
            },
            phone: '1-360-676-1168',
            minAge: 18,
            minAnnualIncome: 100,
            maxAnnualIncome: 500000,
            minLoanAmount: 1000,
            maxLoanAmountFormatted: 75000,
            minCoBorrowerAnnualIncome: 3600,
            hardInquiryConsentRequired: true,
            sliderDefaultAmountType: '',
            defaultLoanAmount: '',
            offerVariationDetails: {},
            othersOnTitle: true,
            finalSubmitPagesCount: 1,
            finalSubmitPages: {
                bankDetails: true,
                miscellaneousFields: {
                    email: false,
                    phone: false,
                    homePhone: 'nodisplay', // nodisplay/optional/required
                    mothersMaidenName: true,
                    ssn: true,
                    last4ssn: false,
                    workPhone: true,
                    desiredLoanAmount: true,
                    desiredTerm: true
                },
                employmentHistoryDuration: 12,
                residenceHistoryDuration: 24,
                page1: {
                    reference: true,
                    driverLicense: true,
                    employmentHistory: true,
                    residencyHistory: true,
                    declarations: false,
                    assetsLiabilities: false,
                    additionalIncome: true,
                },
                page2: {
                    reference: false,
                    driverLicense: false,
                    employmentHistory: false,
                    residencyHistory: true,
                    declarations: false,
                    assetsLiabilities: false,
                    additionalIncome: false,
                }
            },
            desiredLoanAmount: true,
            crossSellPageSubTitle: ''
        } */
	}
};
